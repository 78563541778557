import { Box, styled, Typography } from "@mui/material";

const StyledTxLoaderContent = styled(Box)({
  textAlign: "center",
  "& p": {
    fontSize: 18,
    fontWeight: 500,
  },
});

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "stretch",
  gap: 30,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: 30,
  },
}));

const StyledContract = styled(Box)(({ theme }) => ({
  position: 'relative',
  top: '-24px',
  right: '744px',
  display: 'flex',
  'a' : {
    fontSize: '12px',
    fontWeight: 'bold'
  },
  'img': {
    cursor: 'pointer',
    marginLeft: '5px'
  },
  [theme.breakpoints.down("md")]: {
    top: '-17px',
    right: '483px',
    '.address-link a': {
      fontSize: '9px'
    }
  },
  
}));

const StyledTonDappNomics = styled(Box)(({ theme }) => ({
  display: 'flex',
  'img.tokenomics': {
    width: '113%',
    height: 'auto',
    marginLeft: '-34px',
  },
  'img.animate': {
    position: 'relative',
    right: '232px',
    top: '-36px'
  },
  [theme.breakpoints.down("sm")]: {
    "img.animate": {
      position: 'relative',
      right: '135px',
      top: '2px',
      width: '100px',
      height: '100px'
    },
    'img.tokenomics': {
      width: '112%',
      height: 'auto',
      marginLeft: '-22px',
    },
  },
}));

const StyledTonDappNomicsAnimate = styled(Box)({
  position: 'relative',
  height: 0,
  'img': {
    position: 'relative',
    top: '-114px',
    left: '85px',
  }
});

const SocialsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  height: 0,
  [theme.breakpoints.down("sm")]: {
    justifyContent: 'end',
  },
}));

const SocialsContent = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(1.5),
  position: 'relative',
  top: '-52px',
  right: '-466px',
  [theme.breakpoints.down("sm")]: {
    position: 'unset',
  }
}));

const StyledDescription = styled(Box)(({ theme }) => ({
  marginBottom: '50px',
  position: "relative",
  background: "#fff",
  borderRadius: 16,
  border: "0.5px solid rgba(114, 138, 150, 0.24)",
  boxShadow: "0px 2px 16px rgb(114 138 150 / 8%)",

  "& p": {
    fontSize: 16,
    lineHeight: "24px",
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: '30px',
  },
}));

const ScreenHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  color: "#161C28",
  fontSize: 44,
  [theme.breakpoints.down("md")]: {
    fontSize: 28,
    textAlign: "center",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(8),
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "stretch",
  gap: theme.spacing(5),
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));
const LeftColumnWrapper = styled(Box)({

})

const SubHeadingWrapper = styled(Box)(({ theme }) => ({
  flex: 5,
  background: "#FFFFFF",
  border: "0.5px solid rgba(114, 138, 150, 0.24)",
  boxShadow: "0px 2px 16px rgba(114, 138, 150, 0.08)",
  borderRadius: "24px",
  padding: theme.spacing(3),
}));

const FormHeading = styled(Typography)(({ theme }) => ({
  color: "#161C28",
  fontSize: 20,
  fontWeight: 800,
  marginBottom: theme.spacing(3),
}));

export {
  StyledDescription,
  StyledContainer,
  StyledTxLoaderContent,
  ScreenHeading,
  FormWrapper,
  SubHeadingWrapper,
  FormHeading,
  StyledTonDappNomics,
  StyledTonDappNomicsAnimate,
  LeftColumnWrapper,
  SocialsWrapper,
  SocialsContent,
  StyledContract
};
