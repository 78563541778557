import { useState } from "react";
import { Address } from "ton";
import { Box, Fade, Link, Typography } from "@mui/material";
import { jettonDeployController, JettonDeployParams } from "lib/deploy-controller";
import WalletConnection from "services/wallet-connection";
import { createDeployParams } from "lib/utils";
import { ContractDeployer } from "lib/contract-deployer";
import { Link as ReactRouterLink } from "react-router-dom";
import { ROUTES } from "consts";
import logoRight from "assets/icons/logoRight.svg";
import useNotification from "hooks/useNotification";
import tonDappAnimate from "assets/icons/tonDappMain.gif";
import telegram from "assets/icons/telegram.svg";
import copy from "assets/icons/copy.svg";
import telegramHovered from "assets/icons/telegram-hover.svg";
import twitter from "assets/icons/twitter.svg";
import twitterHovered from "assets/icons/twitter-hover.svg";
import {
  FormWrapper,
  LeftColumnWrapper,
  ScreenHeading,
  SocialsContent,
  SocialsWrapper,
  StyledContract,
  StyledDescription,
  StyledTonDappNomics,
  StyledTonDappNomicsAnimate,
  SubHeadingWrapper,
} from "./styles";
import { Screen, ScreenContent } from "components/Screen";
import analytics, { AnalyticsAction, AnalyticsCategory } from "services/analytics";
import { getUrlParam, toDecimalsBN } from "utils";
import { offchainFormSpec, onchainFormSpec } from "./data";
import { Form } from "components/form";
import Tokenomics from "assets/icons/Tokenomics.svg";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { ImageDeployerWrapper, ImageWrapper } from "components/appLogo/styled";
import { LogoFooterWrapper } from "components/footer/styled";
import { AppLogo } from "components/appLogo";
import { HoverableIcon } from "components/hoverableIcon";
import AddressLink from "components/AddressLink";

const DEFAULT_DECIMALS = 9;

const isOffchainInternal = getUrlParam("offchainINTERNAL") !== null;

let formSpec = isOffchainInternal ? offchainFormSpec : onchainFormSpec;

async function fetchDecimalsOffchain(url: string): Promise<{ decimals?: string }> {
  let res = await fetch(url);
  let obj = await res.json();
  return obj;
}

function DeployerPage() {
  const { showNotification } = useNotification();
  const walletAddress = useTonAddress();
  const [tonconnect] = useTonConnectUI();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigatePreserveQuery();

  async function deployContract(data: any) {
    if (!walletAddress || !tonconnect) {
      throw new Error("Wallet not connected");
    }

    let decimals = data.decimals;
    if (data.offchainUri) {
      let res = await fetchDecimalsOffchain(
        data.offchainUri.replace("ipfs://", "https://ipfs.io/ipfs/"),
      );
      decimals = res.decimals;
    }

    const params: JettonDeployParams = {
      owner: Address.parse(walletAddress),
      onchainMetaData: {
        name: data.name,
        symbol: data.symbol,
        image: data.tokenImage,
        description: data.description,
        decimals: parseInt(decimals).toFixed(0),
      },
      offchainUri: data.offchainUri,
      amountToMint: toDecimalsBN(data.mintAmount, decimals ?? DEFAULT_DECIMALS),
    };
    setIsLoading(true);
    const deployParams = createDeployParams(params, data.offchainUri);
    const contractAddress = new ContractDeployer().addressForContract(deployParams);

    const isDeployed = await WalletConnection.isContractDeployed(contractAddress);

    if (isDeployed) {
      showNotification(
        <>
          Contract already deployed,{" "}
          <ReactRouterLink to={`${ROUTES.jetton}/${Address.normalize(contractAddress)}/`}>
            View contract
          </ReactRouterLink>
        </>,
        "warning",
      );
      setIsLoading(false);
      return;
    }

    try {
      const result = await jettonDeployController.createJetton(params, tonconnect, walletAddress);
      analytics.sendEvent(
        AnalyticsCategory.DEPLOYER_PAGE,
        AnalyticsAction.DEPLOY,
        contractAddress.toFriendly(),
      );

      navigate(`${ROUTES.jetton}/${Address.normalize(result)}`);
    } catch (err) {
      if (err instanceof Error) {
        showNotification(<>{err.message}</>, "error");
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Screen>
      <ScreenContent removeBackground>
        <Fade in>
          <Box>
            <Box mb={3} mt={3.75}>
              <ScreenHeading variant="h5">Mint your token</ScreenHeading>
            </Box>
            
            <ImageDeployerWrapper height={0}>
              <img src={tonDappAnimate} alt="Animate" />
            </ImageDeployerWrapper>
            <FormWrapper>
              <LeftColumnWrapper>
                <SubHeadingWrapper>
                  <Form
                    isLoading={isLoading}
                    submitText="Deploy"
                    onSubmit={deployContract}
                    inputs={formSpec}
                  />
                </SubHeadingWrapper>
                
              <LogoFooterWrapper>
                <AppLogo />
                <ImageWrapper>
                  <img src={logoRight} width={120} height={120} alt="Logo" />
                </ImageWrapper>
              </LogoFooterWrapper>
              </LeftColumnWrapper>
              <Box sx={{ flex: 4 }}>
                <Description />
                <StyledTonDappNomics><img src={Tokenomics} width={320} className="tokenomics" height={220} alt="Logo" />
                <img src={tonDappAnimate} width={200} height={200} alt="Logo" className="animate" />
                <StyledContract>
                  <AddressLink showIcon={true} value={"EQBP2msNbCK59_1qtb1_Gi8Yxfp8ANZdMjRRRT4alNehsLdr"} address="EQBP2msNbCK59_1qtb1_Gi8Yxfp8ANZdMjRRRT4alNehsLdr" />
                  {/* <Link href="https://tonscan.org/jetton/EQBP2msNbCK59_1qtb1_Gi8Yxfp8ANZdMjRRRT4alNehsLdr" title="EQBP2msNbCK59_1qtb1_Gi8Yxfp8ANZdMjRRRT4alNehsLdr" target="_blank">EQBP2msNbCK...alNehsLdr</Link>
                  <img src={copy} width={12} height={12} alt="copy" className="copy" /> */}
                </StyledContract>
                </StyledTonDappNomics>
                <SocialsWrapper>
                  <SocialsContent>
                    <HoverableIcon
                      iconUrl={telegram}
                      hoveredIconUrl={telegramHovered}
                      link="https://t.me/tondappchannel"
                    />
                    <HoverableIcon
                      iconUrl={twitter}
                      hoveredIconUrl={twitterHovered}
                      link="https://twitter.com/TON_Dapp"
                    />
                  </SocialsContent>
                </SocialsWrapper>
                {/* <StyledTonDappNomicsAnimate><img src={tonDappAnimate} width={200} height={200} alt="Logo" /></StyledTonDappNomicsAnimate> */}
              </Box>
            </FormWrapper>
          </Box>
        </Fade>
      </ScreenContent>
    </Screen>
  );
}

export { DeployerPage };

const Spacer = () => {
  return <aside style={{ height: 25 }}></aside>;
};

function Description() {
  return (
    <StyledDescription sx={{ padding: 3 }}>
       <TwitterTimelineEmbed
        sourceType="profile"
        screenName="ton_blockchain"
        options={{height: 450}}
      />
    </StyledDescription>
  );
}
